import React, { useState } from 'react';
import { Text } from 'components/Text';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { OrderItem } from 'components/OrderItem';
import { calculatePriceSum, getStoreCurrencyFormatter } from 'utils';
import { Button } from 'components/Button';
import { Claim, ClaimItemValues } from 'types/claim';
import { Order } from 'types/order';
import { useStore } from 'context/store-context';

type ReorderProps = {
  claimItems: ClaimItemValues[] | undefined;
  handleRemoveItem?: (order: ClaimItemValues) => void;
  handleReorderItems?: (input: any) => void;
  loading?: boolean;
  claim?: Claim;
  handleQuantityUpdate: (value: string, item: ClaimItemValues) => void;
  staticClaimItems: ClaimItemValues[];
  order?: Order | null;
};

export const Reorder = ({
  claimItems,
  handleRemoveItem,
  handleReorderItems,
  loading,
  claim,
  handleQuantityUpdate,
  staticClaimItems,
  order,
}: ReorderProps) => {
  const { storeProperties } = useStore();
  const [input, setInput] = useState({
    notify: storeProperties?.storeClaimsSettings?.notifyCustomerChecked ?? true,
    note: '',
  });

  const handleReorder = () => {
    handleReorderItems && handleReorderItems(input);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
  ) => {
    const { value } = e.target;

    setInput({ ...input, [field]: value });
  };

  const handleCheck = (field: string) => {
    setInput({ ...input, [field]: !input.notify });
  };

  const getOrderDiscount = (
    claim: Claim | undefined,
    claimItems: ClaimItemValues[],
  ) => {
    if (!(claimItems && claimItems.length)) return 0;

    const lineItemsTotalDiscount =
      claimItems && claimItems[0].orderItem
        ? Number(
            calculatePriceSum(
              claimItems?.map((item) => {
                return { ...item, ...item.orderItem };
              }),
              'discountTotal',
            ),
          )
        : 0;
    const additionalOrderDiscount = Number(
      claim?.order?.discountTotal ?? order?.discountTotal ?? 0,
    );
    const orderDiscount = Number(
      Number(additionalOrderDiscount) - Number(lineItemsTotalDiscount),
    ).toFixed(2);
    return Number(orderDiscount);
  };

  const getOrderTotal = (claimItems: ClaimItemValues[]) => {
    const orderItemsTotal =
      claimItems && claimItems?.length > 0 && claimItems[0].orderItem
        ? calculatePriceSum(
            claimItems?.map((item) => {
              const quantity = item.quantity ?? 1;
              const total = item.orderItem.price ?? 0;
              const itemTotal = Number(quantity * total);
              return { ...item, ...item.orderItem, total: itemTotal };
            }),
            'total',
          )
        : claimItems?.length
        ? calculatePriceSum(claimItems, 'total')
        : 0;
    // Not accounting for discount
    // const orderTotal = Number(
    //   Number(orderItemsTotal) - Number(getOrderDiscount(claim, claimItems)),
    // ).toFixed(2);
    return Number(orderItemsTotal);
  };

  const getTotal = (claimItems: ClaimItemValues[]) => {
    const orderTotal = Number(getOrderTotal(claimItems));
    return orderTotal !== 0
      ? getStoreCurrencyFormatter(storeProperties?.currency, orderTotal)
      : '';
  };
  const getTotalAmount = (claimItems: ClaimItemValues[]) => {
    const orderTotal = getOrderTotal(claimItems);
    return orderTotal;
  };

  const reOrderItemsSubTotal = getTotal(claimItems ?? []);

  const getReOrderAmount = (claimItems: ClaimItemValues[]) => {
    const reOrderAmount = Number(getTotalAmount(claimItems ?? []));
    // const orderSubTotal = Number(claim?.order.subtotal ?? order?.subtotal ?? 0);
    // return reOrderAmount > orderSubTotal ? orderSubTotal : reOrderAmount;
    return reOrderAmount;
  };

  const reOrderAmount = getReOrderAmount(claimItems ?? []);

  const claimItemCount = (claimItems ?? [])
    .filter((item) => item.quantity)
    .reduce((total: number, current: ClaimItemValues) => {
      if (current.quantity && current.settlement) {
        return total + Number(current.quantity - current.settlement.quantity);
      }
      return total + Number(current.quantity);
    }, 0);

  return (
    <div className="block sm:flex sm:gap-4 mt-4">
      <div className="sm:w-2/3 border rounded p-5">
        <div className="flex justify-between px-3 py-2 bg-gray-50 rounded-md">
          <Text value="Reorder" type="bold" className="" />
          <QuestionMarkCircleIcon className="h-5 w-5" />
        </div>
        <div className="h-72 overflow-scroll mt-3">
          {claimItems &&
            claimItems.map((claimItem, index) => (
              <OrderItem
                key={claimItem.id}
                order={claimItem?.orderItem}
                onRemoveItem={() =>
                  handleRemoveItem && handleRemoveItem(claimItem)
                }
                onHandleQuantityUpdate={(value) =>
                  handleQuantityUpdate(value, claimItem)
                }
                staticClaimItems={staticClaimItems} //claimItem that does not change when the quantity is updated
                claimItem={claimItem}
                index={index}
                isDisplay
                shouldUseNumberInputType={true}
              />
            ))}
        </div>
        <div className="flex justify-between px-3 py-2 bg-gray-50 rounded-md">
          <Text value="Payment" type="bold" className="" />
        </div>
        <div className="sm border rounded p-5 mt-3 sm:mt-0 h-max">
          <div className="flex justify-between py-3">
            <Text
              value="Order Total"
              type="sm"
              className="text-gray-600 font-bold"
            />
            <Text
              value={`$${claim?.order.total ?? order?.total}`}
              className="text-gray-500"
            />
          </div>
          <div className="flex justify-between py-3">
            <Text
              value="Order Sub Total"
              type="sm"
              className="text-gray-600 font-bold"
            />
            <Text
              value={`$${claim?.order.subtotal ?? order?.subtotal}`}
              className="text-gray-500"
            />
          </div>
          <div className="flex justify-between py-3">
            <Text
              value="Items Sub Total"
              type="sm"
              className="text-gray-600 font-bold"
            />
            <Text value={reOrderItemsSubTotal} className="text-gray-500" />
          </div>
          <div className="flex justify-between py-3">
            <Text
              value="Discount"
              type="sm"
              className="text-gray-600 font-bold"
            />
            <Text
              value={getStoreCurrencyFormatter(
                storeProperties?.currency,
                getOrderDiscount(claim, claimItems ?? []),
              )}
              className="text-gray-500"
            />
          </div>
          <div className="flex justify-between py-3">
            <Text value="Tax" type="sm" className="text-gray-600 font-bold" />
            <Text
              value={
                claimItems && claimItems?.length > 0 && claimItems[0].orderItem
                  ? `$${calculatePriceSum(
                      claimItems?.map((item) => {
                        return { ...item, ...item.orderItem };
                      }),
                      'taxTotal',
                    )}`
                  : claimItems?.length
                  ? `$${calculatePriceSum(claimItems, 'taxTotal')}`
                  : ''
              }
              className="text-gray-500 line-through"
            />
          </div>
          <div className="flex justify-between py-3 mt-1">
            <Text
              value="Reorder Amount"
              type="sm"
              className="text-gray-600 font-bold"
            />
            <Text
              value={getStoreCurrencyFormatter(
                storeProperties?.currency,
                reOrderAmount,
              )}
              type="sm"
              className="text-gray-500"
            />
          </div>
          {claim?.notes && (
            <div className=" mt-4 pb-4">
              <Text
                value="Notes"
                type="h4"
                className="px-3 py-2 bg-gray-50 rounded-md"
              />
              <Text value={claim?.notes} className="text-gray-500 mt-4" />
            </div>
          )}
        </div>
        <div className="mt-6">
          <Text value="Notes" type="bold" className="text-gray-500" />
          <textarea
            rows={1}
            className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-gray-300 rounded-md mr-3 mt-3 p-2"
            placeholder="Notes"
            onChange={(e) => handleChange(e, 'note')}
          />
          <Text
            type="sm"
            value={
              'NB - Resolution notes are visible to customers on the customer portal'
            }
            className="text-gray-400"
          />
          <div className="mt-3 flex items-center">
            <input
              name="notify"
              type="checkbox"
              className="focus:ring-indigo-500 h-3 w-3 text-indigo-600 border-gray-300 rounded mr-1"
              onChange={(e) => handleCheck('notify')}
              checked={input.notify}
            />
            <Text
              value="Send a notification to customers"
              className="text-gray-500"
              type="sm"
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            onClick={handleReorder}
            isLoading={loading}
          >
            Reorder {claimItemCount}{' '}
            {`${claimItemCount > 1 ? 'items' : 'item'}`}
            {' - '}
            {getStoreCurrencyFormatter(
              storeProperties?.currency,
              reOrderAmount,
            )}
          </Button>
        </div>
      </div>
      <div className="sm:w-1/3 border rounded p-5 mt-3 sm:mt-0 h-max">
        <Text
          value="Customer"
          type="bold"
          className="px-3 py-2 bg-gray-50 rounded-md"
        />
        <div className="flex justify-between py-5">
          <Text
            value={claim?.order?.customerName ?? ''}
            type="sm"
            className="text-gray-600 font-bold"
          />
        </div>
        <Text
          value="Contact Information"
          type="bold"
          className="px-3 py-2 bg-gray-50 rounded-md"
        />
        <div className="flex justify-between py-5">
          <Text
            value={claim?.order?.customerEmail ?? ''}
            type="sm"
            className="text-gray-600 font-bold"
          />
        </div>
        <div className="flex justify-between py-5">
          <Text
            value={claim?.order?.customerPhone ?? ''}
            type="sm"
            className="text-gray-600 font-bold"
          />
        </div>
        {claim?.notes && (
          <div className=" mt-4 pb-4">
            <Text
              value="Notes"
              type="h4"
              className="px-3 py-2 bg-gray-50 rounded-md"
            />
            <Text value={claim?.notes} className="text-gray-500 mt-4" />
          </div>
        )}
      </div>
    </div>
  );
};
